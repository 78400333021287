.resetPassword {
  min-width: 480px;
  flex-direction: column;
}

.resetPasswordContainer {
  width: 80%;
}

.buttonGroup {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 15px 0px 0px 0px;
}

@media only screen and (max-width: 480px) {
  .resetPassword {
    min-width: 300px;
  }

  .resetPasswordContainer {
    width: 100%;
  }
}