.noAccessSection {
  width: 100%;
  justify-content: center;
  padding: 15px 0px;
  display: flex;
}

.paginationContainer {
  margin: 10px 0px;
  display: flex;
  width: 100%;
  justify-content: center;
  color: #2196f3
}