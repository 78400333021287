.buttonGroupContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.buttonGroup { 
  border: 2px solid #2196f3;
}

.button {
  color: #2196f3 !important;
  border-radius: 0px;
}

.buttonSelected {
  color: white !important;
  background-color: #2196f3 !important;
  border-radius: 0px !important;
}

.buttonSelected:hover {
  background-color: #4ba7f3 !important;
}

.buttonTitle {
  font-size: 18px;
}
