.popover {
	position: unset !important;
	top: unset !important;
	left: unset !important;
	z-index: unset !important;
	display: unset !important;
	max-width: unset !important;
	font-style: unset !important;
	font-weight: 300 !important;
	line-height: unset !important;
	text-align: unset !important;
	text-align: unset !important;
	text-decoration: unset !important;
	text-shadow: unset !important;
	text-transform: unset !important;
	letter-spacing: unset !important;
	word-break: unset !important;
	word-spacing: unset !important;
	white-space: unset !important;
	line-break: unset !important;
	font-size: unset !important;
	word-wrap: unset !important;
	background-color: transparent !important;
	background-clip: unset !important;
	border: unset !important;
	border-radius: unset !important;
}
