.actionIcon {
  color: #868e96;
  cursor: pointer;
}

.filterWrapper {
  display: flex;
  gap: 10px;
  margin: 20px 0px 10px 0px;
}

.filterInput {
  min-width: 220px;
  border: 1px solid #868e96;
  outline: none;
  padding: 3px 10px;
}

.addUserButton {
  color: white !important;
  background-color: #3daeff !important;
  border-radius: 5px !important;
}

.addUserButton:hover {
  color: white !important;
  background-color: #3daeff !important;
  border-radius: 5px !important;
  box-shadow: 5px #868e96 !important;
}

.rolesPopup {
  padding: 8px 15px;
  background-color: #d7d3d3;
}

.rolesPopupItemDivider {
  height: 1px;
  width: 100%;
}