.wrapper {
  max-width: 700px;
  max-height: 480px;
  display: flex;
  overflow: hidden;
}

.rolesShower {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-y: auto;
  padding: 10px;
}

.challengeCard {
  min-width: 185px;
  padding: 5px 18px;
  margin: 7px 0px;
  box-shadow: rgba(140, 152, 164, 0.25) 2px 3px 3px 0px;
}

.roleSidebarWrapper {
  max-width: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rolesSidebarItem {
  width: 100%;
  padding: 7px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.rolesSidebarItemSelected {
  background-color: #e0e0e0;
  transition: 0.35s;
}

.rolesSidebarItemDivider {
  color: #c3c3c3;
  width: 100%;
  height: 1px;
}

@media only screen and (max-width: 680px) {
  .wrapper {
    width: 90vw;
    max-height: 95vh;
  }
}